import { render, staticRenderFns } from "./table.vue?vue&type=template&id=701a47c8&"
import script from "./table.js?vue&type=script&lang=js&"
export * from "./table.js?vue&type=script&lang=js&"
import style0 from "@/components/files/table-files.scss?vue&type=style&index=0&lang=scss&"
import style1 from "./table.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/luizmottin/Workspace/Trackmob/trackmob-collect/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('701a47c8')) {
      api.createRecord('701a47c8', component.options)
    } else {
      api.reload('701a47c8', component.options)
    }
    module.hot.accept("./table.vue?vue&type=template&id=701a47c8&", function () {
      api.rerender('701a47c8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/donors/table.vue"
export default component.exports