import { formatStatusDonors, dateTimeFormatDate } from '@/helpers'

export default {
  name: 'TDonorsTable',
  data () {
    return {
      thead: [
        { title: 'Status', classes: 'status', key: 'status', order: null },
        { title: 'Doador', classes: '', key: 'fullName', order: null },
        { title: 'Doações ativas', classes: 'right aligned collapsing', key: 'activeSubscriptionsCount', order: null },
        { title: 'Ativo desde', classes: 'collapsing active-since', key: 'createdAt', order: null }
      ]
    }
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    donors: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    itemId: item => item.id,

    formatedData (data) {
      return {
        status: formatStatusDonors(data.status, data.statusLabel),
        name: data.fullName,
        activeSubs: data.activeSubscriptionsCount,
        createdAt: dateTimeFormatDate(data.createdAt)
      }
    },

    orderTable (field, order) {
      const header = this.thead.find( head => head.key === field )
      if (!order || order === 'asc') {
        order = 'desc'
      } else {
        order = 'asc'
      }
      header.order = order

      this.thead.forEach(head => {
        if (head.key !== field) head.order = null
      })

      this.$emit('orderBy', { field, order })
    }
  }
}
